import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';

import dayjs from 'dayjs';
import { AccountType, BankDetails } from '../..';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import { getBiometricsStatus } from '../../utils/getBioMetricStatus';
import BiometricsIcon from './BiometricsIcon';

function getAccountTypeName(accountType: AccountType): string {
  const accountTypeMapping = {
    conta_corrente: 'Conta Corrente',
    conta_poupanca: 'Conta Poupança',
    conta_corrente_conjunta: 'Conta Corrente Conjunta',
    conta_poupanca_conjunta: 'Conta Poupança Conjunta',
  } as Record<AccountType, string>;

  return accountTypeMapping[accountType] || 'Tipo desconhecido';
}

interface BankDetailsCardProps {
  bankName: string;
  bankDetails: BankDetails;
  isSettingsVisible: boolean;
}

interface CreateBiometricsLinkResponse {
  data: {
    url: string;
    base64: string;
    expirationDate: string;
  };
}

function isExpired(expirationDate: string): boolean {
  const now = dayjs();

  return now.isSameOrAfter(expirationDate);
}

export default function BankDetailsCard({
  bankName,
  bankDetails,
  isSettingsVisible,
}: BankDetailsCardProps) {
  const biometricStatus = getBiometricsStatus(bankDetails.status, bankDetails.kycDetailsStatus);

  const { handleSubmit, isLoading } = useHandleSubmit({
    url: '/recipient/create-kyc-link',
    method: 'post',
    authenticated: true,
    data: {
      recipientId: bankDetails?.recipientId,
    },
    onSuccess: {
      callback: (response: CreateBiometricsLinkResponse) => {
        window.location.replace(response.data.url);
      },
    },
  });

  async function handleValidateBiometrics() {
    if (!bankDetails.url || isExpired(bankDetails.expirationDate)) {
      await handleSubmit();
      return;
    }

    window.location.replace(bankDetails.url);
  }

  const isBiometricsButtonVisible = biometricStatus.name === 'pending';

  return (
    <>
      <Card
        border="1px solid rgba(32, 33, 35, 0.05)"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        marginTop={8}
      >
        <CardHeader padding={3}>
          <HStack align="center">
            <Heading fontSize="md" fontWeight="semibold" margin={0}>
              {bankDetails?.legalName}
            </Heading>

            <Text fontSize={{ base: 'xs', xl: 'md' }} color="#EB7129">
              (Conta padrão)
            </Text>
          </HStack>
        </CardHeader>

        <CardBody paddingX={3} paddingY={0}>
          <HStack mt={1}>
            <Text as="strong">Status:</Text>
            <Text color={biometricStatus?.color}>{biometricStatus?.label}</Text>
          </HStack>

          <Flex
            wrap="wrap"
            justify="space-between"
            marginTop={4}
            sx={{ strong: { fontWeight: 'semibold' } }}
            gap={{ base: 0, xl: 4 }}
          >
            <VStack align="start" spacing={0}>
              <HStack>
                <Text as="strong" flexShrink={0}>
                  ID do Recebedor:
                </Text>
                <Text isTruncated>{bankDetails.recipientId}</Text>
              </HStack>

              <HStack>
                <Text as="strong">Data de Cadastro:</Text>
                <Text>{dayjs(bankDetails.createdAt).format('DD/MM/YYYY')}</Text>
              </HStack>
            </VStack>

            <VStack align="start" spacing={0}>
              <HStack>
                <Text as="strong">Conta: </Text>
                <Text>{bankDetails?.conta}</Text>
              </HStack>

              <HStack>
                <Text as="strong">Agência:</Text>
                <Text>{bankDetails?.agencia}</Text>
              </HStack>
            </VStack>

            <VStack align="start" spacing={0}>
              <HStack>
                <Text as="strong">Banco: </Text>
                <Text textTransform="capitalize" noOfLines={1}>
                  {bankName?.toLowerCase() ?? bankDetails.bankCode}
                </Text>
              </HStack>

              <HStack>
                <Text as="strong">Tipo de conta:</Text>
                <Text>{getAccountTypeName(bankDetails.type)}</Text>
              </HStack>
            </VStack>
          </Flex>
        </CardBody>

        <CardFooter padding={3}>
          {isBiometricsButtonVisible && (
            <Button
              onClick={handleValidateBiometrics}
              colorScheme="primary"
              color="secondary.500 !important"
              sx={{ svg: { width: '18.65px', color: 'secondary.500' } }}
              leftIcon={<BiometricsIcon />}
              width={{ base: '100%', xl: 'initial' }}
              size={{ base: 'sm', xl: 'md' }}
              isLoading={isLoading}
            >
              Validar Biometria
            </Button>
          )}
        </CardFooter>
      </Card>

      {isSettingsVisible && (
        <Text color="#20212380" mt={2}>
          Para alterar ou adicionar uma nova conta clique em “Configurações”.
        </Text>
      )}
    </>
  );
}
